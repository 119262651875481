<template>
  <div class="login-box">
    <!-- <img
      class="login-box-cover-img"
      src="../assets/image/woyaoyou_bg_login@2x.png"
    /> -->
    <div class="login-main">
      <div class="ctenrs">
        <!-- <div style="width: 47%; height: 100%">
          <img
            v-if="siteInfo != null"
            src="../assets/image/loginsx.png"
            style="width: 100%; height: 100%"
          />
        </div> -->
        <div class="titles">
          <div class="jianbian">Hi,小邦管家</div>
          <div style="color: #666666; font-size: 24px; margin: 0 20px">/</div>
          <div style="color: #666666; font-size: 24px">让实体门店更简单</div>
        </div>
        <div class="login-form-box" style="width: 100%">
          <div class="bantop">
            <div
              style="color: #666666; font-size: 18px"
              :class="showcolor == 0 ? 'blue' : ''"
              @click="showcolor = 0"
            >
              账号密码登录
            </div>
            <div style="color: #666666; font-size: 18px; margin: 0 20px">/</div>
            <div
              style="color: #666666; font-size: 18px"
              :class="showcolor == 0 ? '' : 'blue'"
              @click="showcolor = 1"
            >
              验证码登录
            </div>
          </div>
          <div class="ercode">
            <img
              src="../assets/image/编组.png"
              alt=""
              style="width: 32px; height: 32px"
              class="codeimg"
            />
          </div>
          <a-form v-if="showcolor == 0" :model="form" style="margin-top: 30px">
            <a-form-item label="手机号码">
              <a-input
                v-model="form.mobile"
                placeholder="请输入手机号"
                style="border: none; border-bottom: 1px solid #dfdfdf"
              >
              </a-input>
              <img src="../assets/image/编组 11.png" alt="" class="iconimg" />
            </a-form-item>
            <a-form-item label="密码">
              <a-input
                v-model="form.pwd"
                type="password"
                placeholder="请输入密码"
                style="border: none; border-bottom: 1px solid #dfdfdf"
              >
              </a-input>
              <img
                src="../assets/image/编组 11(1).png"
                alt=""
                class="iconimg"
              />
            </a-form-item>
            <a-form-item>
              <a-button
                style="width: 100%; margin-top: 30px"
                @click="loginAct2"
                type="primary"
                size="large"
                >立即登录</a-button
              >
              <div
                style="
                  font-size: 14px;
                  font-weight: 400;
                  color: #8692a6;
                  margin-top: 5px;
                "
              >
                登录即表示同意《注册协议》《个人信息处理规则》
              </div>
            </a-form-item>
          </a-form>
          <a-form v-if="showcolor == 1" :model="form" style="margin-top: 30px">
            <a-form-item label="手机号码">
              <a-input
                v-model="form.mobile"
                placeholder="请输入手机号"
                style="border: none; border-bottom: 1px solid #dfdfdf"
              >
              </a-input>
              <img src="../assets/image/编组 11.png" alt="" class="iconimg" />
            </a-form-item>
            <a-form-item label="验证码">
              <div style="dispaly:flex;flex-direction: row;align-content: center;">
                <a-input
                v-model="form.code"
                :maxLength="6"
                placeholder="请输入验证码"
                style="border: none; border-bottom: 1px solid #dfdfdf;"
              ></a-input>

              <countdown-djs
              style="width:80px;padding-top:5px;"
               class="iconimg" 
                :mobile="form.mobile"
                :code="code"
                :type="'login'"
              ></countdown-djs>
              </div>
              <!-- <img src="../assets/image/编组 11(1).png" alt="" class="iconimg"> -->
            </a-form-item>
            <a-form-item>
              <a-button
                style="width: 100%; margin-top: 30px"
                @click="loginAct"
                type="primary"
                size="large"
                >立即登录</a-button
              >
              <div
                style="
                  font-size: 14px;
                  font-weight: 400;
                  color: #8692a6;
                  margin-top: 5px;
                "
              >
                登录即表示同意《注册协议》《个人信息处理规则》
              </div>
            </a-form-item>
          </a-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import countdownDjs from "../components/countdown/djs.vue";
export default {
  components: {
    countdownDjs,
  },
  data() {
    return {
      type: "1",
      loading: false,
      showcolor: 0,
      code: "",
      form: {
        mobile: "",
        code: "",
        pwd: "",
      },
      siteInfo: null,
    };
  },
  created() {
    if (localStorage.getItem("account-token")) {
      this.$router.push("/mendian");
    } else {
      this.getSiteInfo();
      this.getCode();
    }
  },

  mounted() {
    //绑定事件
    window.addEventListener("keydown", this.keyDown);
  },
  methods: {
    getSiteInfo() {
      this.$http
        .api("platform/siteInfo")
        .then((res) => {
          this.siteInfo = res.site;
        })
        .catch((res) => {
          console.log(res);
        });
    },
    getCode() {
      this.$http
        .api("platform/account/getCode", {})
        .then((res) => {
          this.code = res.code;
        })
        .catch((res) => {
          console.log(res);
        });
    },
    loginAct() {
      if (this.loading == true) return;
      this.loading = true;
      this.$http
        .api("platform/account/login", {
          mobile: this.form.mobile,
          code: this.form.code,
          token: this.code,
        })
        .then((res) => {
          this.loading = false;
          localStorage.setItem("account-token", res.auth_token);
          this.$message.success("登录成功", 1, () => {
            this.$router.push("/mendian");
          });
        })
        .catch((res) => {
          console.log(res);
          this.loading = false;
        });
    },
    loginAct2() {
      if (this.loading == true) return;
      this.loading = true;
      this.$http
        .api("platform/account/pwdLogin", {
          mobile: this.form.mobile,
          pwd: this.form.pwd,
        })
        .then((res) => {
          this.loading = false;
          localStorage.setItem("account-token", res.auth_token);
          this.$message.success("登录成功", 1, () => {
            this.$router.push("/mendian");
          });
        })
        .catch((res) => {
          console.log(res);
          this.loading = false;
        });
    },
    keyDown(e) {
      //如果是回车则执行登录方法
      if (e.keyCode == 13) {
        if (this.type == "1") {
          this.loginAct2();
        } else {
          this.loginAct();
        }
      }
    },
  },
  destroyed() {
    window.removeEventListener("keydown", this.keyDown, false);
  },
};
</script>

<style>
.iconimg {
  position: absolute;
  right: 5px;
  top: -5px;
}
.blue {
  color: #006aff !important;
  font-size: 20px !important;
}
.bantop {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.jianbian {
  /* color: #333333; */
  font-size: 24px;
  font-weight: 600;
  background-image: -webkit-linear-gradient(bottom, #4ea6f3, #2b4dd7, #6159e0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.ercode {
  background: #f2f7ff;
  position: absolute;
  right: 0;
  top: 0;
  border-left: 50px solid #fff;
  border-top: 50px solid transparent;
  border-right: 50px solid transparent;
  border-bottom: 50px solid #fff;
  border-radius: 15px;
}
.codeimg {
  position: absolute;
  top: -40px;
  left: 8px;
}
.titles {
  display: flex;
  padding: 20px;
  /* justify-content: center; */
  /* align-content: center; */
}
.ctenrs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  height: 520px;
}
.login-box {
  width: 100vw;
  height: 100vh;
  /* background: linear-gradient(135deg, #4772FF 0%, #5851FF 100%); */
  background: url("../assets/image/bg1.png") center center no-repeat;
  background-size: cover;
  position: relative;
}
.login-main {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
}
.login-box-cover-img {
  position: absolute;
  bottom: 0px;
  width: 100vw;
}
.login-form-box {
  position: relative;
  /* width: 420px; */
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 32px -8px rgba(0, 0, 0, 0.02);
  /* border-radius: 24px; */
  border-radius: 15px;
  /* margin-top: 36px; */
  padding: 30px 30px;
}
.login-input-item {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #dcdfe6;
  padding: 0 5px 0 0;
}
.login-input-item .ant-input {
  border: none;
  font-size: 14px;
}
.login-input-item .ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-left: 66px;
}
.login-input-item .mobile {
  width: 100%;
}
.login-input-item .code {
  width: 200px;
}
.login-input-item .ant-input-affix-wrapper .ant-input-prefix {
  color: #a6aec2;
  font-size: 14px;
}
.login-input-item .ant-input:focus {
  border: none;
  box-shadow: none;
}
.ant-tabs-nav .ant-tabs-tab-active {
  color: #846ff9 !important;
  border-bottom: #846ff9 !important;
}
.login-btn .ant-btn {
  height: 50px;
  border-radius: 5px;
  font-size: 16px;
  background-color: #846ff9;
}
/* .login-input-item .ant-input-affix-wrapper .ant-input-prefix{
	font-size: 14px !important;
} */
</style>
